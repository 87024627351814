@font-face {
  font-family: ui-icons;
  src: url("~@anwb/icons/dist/ui.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: illustrative-icons;
  src: url("~@anwb/icons/dist/illustrative.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ui-fill;
  src: url("~@anwb/icons/dist/ui-fill.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

body,
html {
  margin: 0;
  color: #295785;
  font-family: Verdana, Arial, sans-serif, ui-icons, illustrative-icons;
  font-size: 15px;
  line-height: 1.6;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
